<template>
  <div class="wallet-methods">
    <div class="wallet-methods__header">

      <wallet-tabs
        v-if="props.showTabs"
        :selected="props.selectedTab"
        @changeTab="emit('changeTab', $event)"
      />

      <div v-else class="wallet-methods__header-title">
        {{ props.modalTitle }}
      </div>
    </div>

    <wallet-region />

    <balance :withdraw="props.selectedTab === 'withdraw'">
      <form-input-payments
        v-if="props.selectedTab === 'deposit'"
        v-model:activeMethod="currentDepositMethod"
        :items="depositMethods"
        @update:activeMethod="handleMethodChanged('deposit')"
        @methodClick="emit('methodClick')"
      />

      <form-input-payments
        v-if="props.selectedTab === 'withdraw'"
        v-model:activeMethod="currentWithdrawMethod"
        :items="withdrawMethods"
        @update:activeMethod="handleMethodChanged('withdraw')"
        @methodClick="emit('methodClick')"
      />

      <div
        v-if="showNotAvailableText"
        class="wallet-modal__empty-methods"
      >
        <atomic-icon id="info" />

        <span>
          {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.notAvailableText') }}
        </span>
      </div>
    </balance>

    <wallet-dots
      :itemsCount="2"
      :activeIndex="0"
    />
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from "pinia";

  const props = defineProps<{
    showTabs: boolean;
    selectedTab: string;
    modalTitle: string;
  }>()

  const currentDepositMethod = defineModel('currentDepositMethod');
  const currentWithdrawMethod = defineModel('currentWithdrawMethod');

  const emit = defineEmits(['changeTab', 'methodClick']);
  const { getContent } = useProjectMethods();
  const walletStore = useWalletStore();
  const {
    depositMethods,
    withdrawMethods
  } = storeToRefs(walletStore);

  const globalStore = useGlobalStore();
  const {
    popupsData,
    defaultLocalePopupsData
  } = storeToRefs(globalStore);

  const showNotAvailableText = computed(() => {
    return (!depositMethods.value?.length && props.selectedTab === 'deposit')
      || (!withdrawMethods.value?.length && props.selectedTab === 'withdraw');
  })

  const handleMethodChanged = (walletOperationType: 'deposit'|'withdraw'): void => {
    useEvent('analyticsEvent', {
      event: 'walletChangeMethod',
      walletOperationType
    });
  }
</script>

<style src="~/assets/styles/components/wallet/methods.scss" lang="scss"/>